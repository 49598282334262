import type { ComponentType, ReactNode } from "react";
import React, { Fragment } from "react";

type Components = ComponentType | [ComponentType, { [key: string]: any }];

interface Props {
	providers: any[];
	// children?: JSX.Element | JSX.Element[] | Element | Element[];
	children?: ReactNode;
}

export const Compose = ({ providers, children }: Props) => (
	<Fragment>
		{providers.reduceRight((acc, curr, currentIndex) => {
			const [Provider, props] = Array.isArray(curr)
				? [curr[0], curr[1]]
				: [curr, {}];

			// return React.createElement(Provider, props, acc);
			// @ts-ignore
			return (
				<Provider key={`provider-${currentIndex}`} {...props}>
					<Fragment>{acc}</Fragment>
				</Provider>
			);
		}, children)}
	</Fragment>
);
